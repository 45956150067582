.skills-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  padding: 10% 10% 10%;
  position: absolute;
}

  /* Mobile */
  @media (max-width: 767px) {
    .skills-container {
      flex-direction: column;
      margin-top: 18%;
      margin-left: 0;
    }
  }

.skills-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 10%;
}

  /* Mobile */
  @media (max-width: 767px) {
    .skills-demo {
      margin-bottom: 10%;
    }
  }

.skills-demo img {
  max-width: 70%;
  height: auto;
  margin-bottom: 20%;
}

  /* Mobile */
  @media (max-width: 767px) {
    .skills-demo img {
      margin-bottom: 7%;
    }
  }

.skills-demo h2 {
  font-size: 2rem;
  color: #F8C630;
  padding-bottom: 5%;
}

.skills-demo h3 {
  font-size: 1.5rem;
  color: #fff;
}

.skills-intro {
  width: 100%;
  max-width: 800px;
}

  /* Mobile */
  @media (max-width: 767px) {
    .skills-intro {
      margin-bottom: 30%;
      align-items: center;
      align-content: center;
      text-align: center;
    }
  }

.skills-intro h2 {
  font-size: 1.5rem;
  color: #fff;
  margin-top: 20px;
}

.skills-intro h3 {
  color: #F8C630;
}

.skills-intro ul {
  list-style-type: none;
  padding: 0;
}

.skills-intro ul li {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  color: #fff;
  padding: 5px 0;
}

.skills-intro-hr {
  margin: 20px 0;
  border: 1px solid #fff;
  width: 70%;
}

  /* Mobile */
  @media (max-width: 767px) {
    .skills-intro-hr {
      justify-items: center;
      width: 100%;
    }
  }

.skills-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.skills-button:hover {
  background-color: #38302E;
  color: #F8C630;
}

.skills-button, .download-resume-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.skills-button:hover, .download-resume-button:hover {
  background-color: #38302E;
  color: #F8C630;
}
