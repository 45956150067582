@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;400;700&family=Bungee&family=Raleway:wght@100;400&display=swap');

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", serif;
  background: #283F3B;
}

h1 {
  font-family: "Bungee", sans-serif;
}

h2, h3 {
  font-family: "Roboto Slab", 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

p {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 20px;
}


/* Mobile */
@media (max-width: 767px) {
  
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1024px) {

}

/* Desktop */
@media (min-width: 1025px) {

}
