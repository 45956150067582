@import "../../index.css";

  .home-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    min-height: 100vh;
    position: absolute;
    padding: 20px;
    margin-left: 5%;
  }

  /* Mobile */
  @media (max-width: 767px) {
    .home-container {
      flex-direction: column-reverse;
      padding: 10px;
      margin-left: 0%;
      margin-top: 5%;
    }
  }
  
  .home-intro {
    margin-bottom: 20px;
    text-align: center;
  }

  @media (max-width: 767px) {
    .home-intro {
      margin-bottom: 20%;
    }
  }
  
  .home-intro h1 {
    font-size: 2.5rem;
    color: #fff;
  }
  
  .home-demo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    border-radius: 10px;
  }
  
  .home-demo h2 {
    font-size: 2rem;
    color: #555;
  }

  @media (max-width: 767px) {
    .home-demo h2 {
      font-size: 1rem;
    }
  }
  

.home-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
}

.home-intro {
  flex: 1;
  text-align: center;
}

.home-intro h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.home-intro h2 {
  font-size: 2rem;
  color: #F8C630;
  margin-bottom: 20px;
}

.home-intro p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #fff;
  margin-bottom: 30px;
}

.home-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.home-button:hover {
  background-color: #38302E;
  color: #F8C630;
}

.home-demo {
  flex: 1;
  text-align: center;
}

.home-demo img {
  max-width: 70%;
  height: auto;
  border-radius: 50%;
}

/* Mobile */
@media (max-width: 767px) {
  .home-demo img {
    max-width: 100%;
  }
}