.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  position: absolute;
}

/* Mobile */
@media (max-width: 767px) {
  .about-container {
    flex-direction: column;
    margin: 10% 0%;
  }
}

.about-demo {
  flex: 1;
  text-align: center;
}

.about-demo img {
  max-width: 80%;
  height: auto;
  border-radius: 50%;
  padding-bottom: 20%;
}

/* Mobile */
@media (max-width: 767px) {
  .about-demo img {
    padding-bottom: 5%;
  }
}

.about-intro {
  flex: 1;
  margin-right: 10%;
}

/* Mobile */
@media (max-width: 767px) {
  .about-intro {
    margin-bottom: 20%;
    align-items: center;
    align-content: center;
    text-align: center;
    padding-left: 10%;
    padding-bottom: 10%;
  }
}

.about-intro h2 {
  font-size: 2rem;
  color: #F8C630;
  margin-bottom: 20px;
}

.about-intro p {
  font-size: 1rem;
  line-height: 1.6;
  color: #fff;
  margin-bottom: 15px;
}

.learn-more-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.learn-more-button:hover {
  background-color: #38302E;
  color: #F8C630;
}
