.navbar {
  background-color: #38302E;
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo a {
  color: #F8C630;
  font-size: 2rem;
  text-decoration: none;
}

.navbar-menu {
  display: block;
  cursor: pointer;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  position: relative;
  z-index: 11;
}

.menu-icon span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #F8C630;
  transition: all 0.3s ease;
}

.menu-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon.open span:nth-child(2) {
  opacity: 0;
}

.menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-links-container {
  display: none; /* Hide the links container by default */
}

.navbar-links-container.show {
  display: block; /* Show the links container when menuOpen is true */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 39, 39, 0.95); /* Slightly transparent background */
  text-align: center;
  z-index: 9;
  padding-top: 4rem; /* Add padding to top of links container to avoid overlap with navbar */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
}

.navbar-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-links li {
  margin-top: 2rem;
}

.navbar-links a {
  display: block; /* Ensure menu items are displayed as blocks */
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.3s;
}

.navbar-links a:hover {
  color: #F8C630;
}

/* Web specific styles */
@media (min-width: 768px) {
  .navbar-menu {
    display: none; /* Hide menu button for web view */
  }

  .navbar-links-container {
    display: flex; /* Display links container for web view */
    align-items: center;
    position: static;
    background-color: transparent;
    box-shadow: none; /* Remove shadow for web view */
  }

  .navbar-links {
    display: flex; /* Display menu items in a row for web view */
  }

  .navbar-links li {
    margin-left: 2rem;
    margin-bottom: 0; /* Remove bottom margin for web view */
  }

  .navbar-links a {
    font-size: 1.2rem; /* Decrease font size for web view */
  }
}
