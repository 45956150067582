.contact-container {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0% 10%;
  position: relative; /* Adjusted from absolute to relative */
}

/* Mobile */
@media (max-width: 767px) {
  .contact-container {
    flex-direction: column-reverse;
    margin: 0;
    padding: 30% 5%;
  }
}

.contact-img img {
  max-width: 70%;
  height: auto;
}

/* Mobile */
@media (max-width: 767px) {
  .contact-img img {
    display: none;
  }
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 800px;
}

.contact-form-intro {
  margin-bottom: 20px;
}

/* Mobile */
@media (max-width: 767px) {
  .contact-form-intro {
    margin-bottom: 10%;
  }
}

.contact-form-intro h2 {
  font-size: 2rem;
  color: #F8C630;
  padding-bottom: 3%;
}

.contact-form-intro p {
  margin: 5px 0;
  font-size: 1rem;
  color: #fff;
  padding-bottom: 1%;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid blue;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
}

.contact-send-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-send-button:hover {
  background-color: #38302E;
  color: #F8C630;
}

.contact-form {
  position: relative;
}

.contact-form .form-message {
  position: absolute;
  bottom: -30px; 
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
}

.contact-form .success {
  color: #4CAF50;
}

.contact-form .error {
  color: #F44336;
}
