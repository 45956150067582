.footer {
    background-color: #38302E;
    color: #fff;
    padding: 20px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5%;
}

.footer-left p {
    font-size: 0.9rem;
}

/* Mobile */
@media (max-width: 767px) {
    .footer-left p {
        font-size: 0.8rem;
    }
  }

.footer-right {
    display: flex;
    gap: 10px;
}

.footer-right .social-icon {
    display: inline-block;
}

.footer-right .social-icon img {
    width: 24px;
    height: 24px;
}