.portfolio-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  margin: 8% 10%;
  position: absolute;
}

  /* Mobile */
  @media (max-width: 767px) {
    .portfolio-container {
      flex-direction: column;
      margin: 20% 5%;
    }
  }

.portfolio-header-container {
  flex-direction: column;
  align-items: center;
}

  /* Mobile */
  @media (max-width: 767px) {
    .portfolio-header-container {
      max-width: 75%;
      align-items: center;
      margin-left: 20%;
    }
  }

.portfolio-header-container img {
  max-width: 70%;
  height: auto;
  padding-bottom: 30%;
}

@media (max-width: 767px) {
  .portfolio-header-container img {
    max-width: 70%;
    height: auto;
    padding-bottom: 30%;
  }
}

.portfolio-header-container h2 {
  font-size: 2rem;
  color: #F8C630;
  padding-left: 20%;
  padding-bottom: 10%;
}

@media (max-width: 767px) {
  .portfolio-header-container h2 {
    padding-left: 15%;
    padding-top: 10%;
  }
}

.portfolio-image-carousel-container {
  position: relative;
  max-width: 800px;
  margin: 20px auto;
  overflow: hidden;
}

  /* Mobile */
  @media (max-width: 767px) {
    .portfolio-image-carousel-container {
      width: 100%;
      margin: 10px 0;
    }
  }

.portfolio-carousel-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

/* Mobile */
@media (max-width: 767px) {
  .portfolio-carousel-container {
    margin-bottom: 15%;
  }
}


.portfolio-carousel-slide {
  min-width: 100%;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  margin-bottom: 15%;
}

.portfolio-carousel-slide h3 {
  font-size: 1.5rem;
  padding-bottom: 2%;
  color: #F8C630;
}

.portfolio-carousel-slide p {
  font-size: 1rem;
  padding-bottom: 2%;
  color: #fff;
}

.project-button {
  padding: 8px 16px;
  font-size: 0.9rem; 
}

  /* Mobile */
  @media (max-width: 767px) {
    .portfolio-carousel-slide {
      padding: 10px;
    }

    .portfolio-carousel-slide h3 {
      font-size: 1.2rem;
    }

    .portfolio-carousel-slide p {
      font-size: 0.9rem;
    }

    .project-button {
      padding: 8px 16px;
      font-size: 0.9rem; 
    }
  }

  /* Mobile */
  @media (max-width: 767px) {
    .portfolio-carousel-container {
      padding: 10px;
      flex-direction: column;
    }
  }


.portfolio-image-container {
  width: 100%;
  max-width: 600px;
  height: 400px;
  margin: 0 auto 20px auto;
  overflow: hidden;
}

.portfolio-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

  /* Mobile */
  @media (max-width: 767px) {
    .portfolio-image-container {
      max-width: 100%;
      height: auto;
    }
  }

.project-button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.project-button:hover {
  background-color: #38302E;
  color: #F8C630;
}

.portfolio-carousel-button {
  cursor: pointer;
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  background-color: rgba(235, 231, 225, 0.445);
  border: none;
  padding: 10px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-carousel-button.left {
  left: 10px;
}

.portfolio-carousel-button.right {
  right: 10px;
}

.portfolio-carousel-button img {
  width: 20px;
  height: 20px;
}

.portfolio-carousel-button.left img {
  transform: rotate(180deg);
}

.portfolio-carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/*mobile*/
@media (max-width: 767px) {
  .portfolio-carousel-button {
    display: none;
  }
}